import React from 'react'
import './Loder.css';
const Loder = () => {
    return (
        <div class="loader-wrapper">
        <div class="loader-container">
          <h1 class="lt">&lt;</h1>
          <h1 class="loader">/</h1>
          <h1 class="lt" id="ltNd">&lt;</h1>
        </div>
      </div>
    )
}

export default Loder
